<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        v-on="on"
        text x-small
      >
        Court grouper
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Court Grouper</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog=false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" md="6" v-for="(g, i) in groups" :key="i" >
              <v-card class="fill-height d-flex flex-column">
                <v-card-title class="title pt-0">
                  <v-text-field
                    single-line
                    v-model="g.name"
                    color="color3"
                    hide-details
                    autofocus
                  ></v-text-field>
                </v-card-title>
                <v-card-text class="py-0 grow">
                  <v-combobox
                    v-model="g.courts"
                    :items="courts"
                    chips deletable-chips
                    label="Courts"
                    multiple
                    color="color3"
                    item-color="color3"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeChip(g.courts, item)"
                      >
                        <span>{{ item }}</span>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-card-text>
                <v-card-text class="pa-0">
                  <div class="text-end">
                    <v-btn
                      color="error"
                      text icon x-small
                      @click.stop="remove(i)"
                    >
                      <v-icon>fas fa-trash</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card min-height="200" color="grey lighten-2" class="fill-height justify-center d-flex align-center">
                  <v-btn fab color="grey" @click.stop="addGroup">
                    <v-icon color="grey lighten-3">fas fa-plus</v-icon>
                  </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-fab-transition>
          <v-btn
            :key="`save-${dirty}`"
            color="success white--text"
            small fab
            :disabled="!dirty"
            :loading="loading"
            @click.stop="save"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['tournament'],
  data () {
    return {
      dialog: false,
      groups: [],
      loading: false
    }
  },
  computed: {
    jProps () {
      return this.tournament.jProps
    },
    savedGroups () {
      return (this.jProps && this.jProps.courtGroups) || []
    },
    courts () {
      let courts = [...this.tournament.fullTimeline.filter(f => f.court).map(m => m.court)]
      courts = [...new Set(courts)].sort()
      return courts
    },
    dirty () {
      return JSON.stringify(this.savedGroups) !== JSON.stringify(this.groups)
    }
  },
  methods: {
    init () {
      this.groups = []
      if (this.jProps && this.jProps.courtGroups) this.groups = JSON.parse(JSON.stringify(this.jProps.courtGroups))
    },
    addGroup () {
      this.groups.push({ name: 'New Group', courts: [] })
    },
    removeChip (arr, item) {
      arr.splice(arr.indexOf(item), 1)
    },
    remove (i) {
      this.groups.splice(i, 1)
    },
    save () {
      this.loading = true
      const j = this.tournament.jProps || {}
      j.courtGroups = this.groups
      this.tournament.jProps = j
      this.tournament.saveJProps()
        .then(r => { this.dialog = false })
        .catch(e => console.log(e.response))
        .finally(f => { this.loading = false })
    }
  },
  watch: {
    dialog: function (v) {
      v && this.init()
    }
  }
}
</script>
