var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"color3","text":"","x-small":""}},on),[_vm._v(" Court grouper ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("Court Grouper")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"color2 color2Text--text","small":"","fab":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.dialog=false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.groups),function(g,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"fill-height d-flex flex-column"},[_c('v-card-title',{staticClass:"title pt-0"},[_c('v-text-field',{attrs:{"single-line":"","color":"color3","hide-details":"","autofocus":""},model:{value:(g.name),callback:function ($$v) {_vm.$set(g, "name", $$v)},expression:"g.name"}})],1),_c('v-card-text',{staticClass:"py-0 grow"},[_c('v-combobox',{attrs:{"items":_vm.courts,"chips":"","deletable-chips":"","label":"Courts","multiple":"","color":"color3","item-color":"color3"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeChip(g.courts, item)}}},'v-chip',attrs,false),[_c('span',[_vm._v(_vm._s(item))])])]}}],null,true),model:{value:(g.courts),callback:function ($$v) {_vm.$set(g, "courts", $$v)},expression:"g.courts"}})],1),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"error","text":"","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove(i)}}},[_c('v-icon',[_vm._v("fas fa-trash")])],1)],1)])],1)],1)}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"fill-height justify-center d-flex align-center",attrs:{"min-height":"200","color":"grey lighten-2"}},[_c('v-btn',{attrs:{"fab":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.addGroup($event)}}},[_c('v-icon',{attrs:{"color":"grey lighten-3"}},[_vm._v("fas fa-plus")])],1)],1)],1)],2)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-fab-transition',[_c('v-btn',{key:("save-" + _vm.dirty),attrs:{"color":"success white--text","small":"","fab":"","disabled":!_vm.dirty,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.save($event)}}},[_c('v-icon',[_vm._v("fas fa-save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }